/*=========================================
** GROUPS COMPARE VIEW CONTAINER**
==========================================*/
.groups-compare-view {
  display: grid;
  grid-gap: $grid-gutter;
  // grid-template-rows: min-content 1fr;
  height: calc(100vh - #{$header-height});
  overflow-y: scroll;
  padding: $default-padding;

  .nodes-wrapper {
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: 1fr 1fr;
    margin-bottom: calc(#{$default-margin} * 2);

    .group-section-title {
      color: $white;
      padding-bottom: $default-padding;
      text-transform: uppercase;
    }
  }
}
