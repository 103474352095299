.back-link {
  position: absolute;
  right: 0;
  top: rem-calc(60);
  span {
    align-items: center;
    animation: slideIn $medium-timing $std-anim;
    color: $white;
    display: flex;
    padding: $default-padding;
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(100%);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
    .svg-icon-holder {
      color: $hl-pink;
      display: flex;
    }
  }
}
