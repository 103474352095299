/*=========================================
** PASSWORD CHECK **
==========================================*/

.password-check {
  grid-column: 1 / span 3;

  .profile-box-title {
    color: $white;
    text-transform: uppercase;
  }

  .password-forms-holder {
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: 1fr 1fr;

    .check-password,
    .new-password {
      padding-top: $default-padding;

      label {
        color: $white;
        text-transform: uppercase;
        // todo fix form stylings
      }

      .submit-button {
        width: 100%;
      }
    }
  }
}
