table {
  border-collapse: collapse;
  min-width: 100%;

  .table-headings,
  .table-sub-headings {
    background-color: $table-header-bg-color;
    color: $table-header-text-color;
    td {
      border-bottom: $table-header-borders;
      padding-bottom: 0.7em;
      padding-top: 0.7em;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .table-results-row {
    td {
      border: $table-borders;
      text-align: center;
    }
  }

  &.table-container {
    tr {
      td {
        color: #fff;
        text-align: center;
      }
      td:first-child {
        text-align: left;
      }

      &:hover {
        background-color: #1f2027;
        td {
          color: #fff;
        }
      }
    }
  }
}
