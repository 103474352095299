/*=========================================
** GROUPS  CONTAINER**
==========================================*/

.groups {
  display: grid;

  &-content {
    min-width: 100%;

    .groups-sidebar {
      display: grid;
      grid-template-columns: 1fr rem-calc(30);
      height: calc(100vh - #{$header-height});
      left: 0;
      overflow-x: hidden;
      overflow-y: scroll;
      position: absolute;
      z-index: 1;

      &-content {
        background-color: $hl-grey-dark;
        display: flex;

        &-inner {
          border-left: rem-calc(1) solid $hl-black;
          max-width: 0;
          opacity: 0;
          transition: all $short-timing $std-anim;
          visibility: hidden;

          &:first-of-type {
            border-left: none;
          }

          &.active {
            background: linear-gradient(90deg, $g-dark, $g-light);
            max-width: 25vw;
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &-tabs {
        display: flex;
        flex-direction: column;

        .groups-sidebar-tab {
          align-items: center;
          background-color: $hl-black;
          border-bottom: $primary-bd;
          border-radius: 0 rem-calc(5) rem-calc(5) 0;
          border-right: $primary-bd;
          border-top: $primary-bd;
          color: $white;
          display: flex;
          flex-direction: column;
          margin-bottom: $default-margin;
          padding: calc(#{$default-padding} / 2) 0;
          transition: all $short-timing $std-anim;

          &-text {
            white-space: nowrap;
            writing-mode: vertical-rl;
          }

          .svg-icon-holder {
            align-content: center;
            display: flex;
            justify-content: center;
            line-height: 1;
            padding-right: calc(#{$default-padding} / 2);
            transform: rotateZ(90deg);
          }

          &:hover {
            background-color: $hl-grey-dark;
            cursor: pointer;
          }

          &.active {
            background-color: $hl-pink;
          }

          &:first-child {
            margin-top: $default-margin;
          }
        }
      }
    }
  }

  &-inner-content {
    padding-left: rem-calc(50);
    position: relative;
    .loading {
      left: 0;
    }
  }
}

.groups-compare-list,
.groups-maker {
  color: $white;
  padding: $default-padding;
}

@import "./groups/groups-boxes";
