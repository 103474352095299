/****************************************
 *** FONT FALLBACKS ***
 *****************************************/

/* stylelint-disable */
// LIGHT
@font-face {
  font-display: swap;
  font-family: "RubikFallback";
  font-weight: 300;
  src: url("../fonts/Rubik-Light.ttf");
}

//LIGHT ITALIC
@font-face {
  font-display: swap;
  font-family: "RubikFallback";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/Rubik-LightItalic.ttf");
}

// REGULAR
@font-face {
  font-display: swap;
  font-family: "RubikFallback";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Rubik-Regular.ttf");
}

// REGULAR ITALIC
@font-face {
  font-display: swap;
  font-family: "RubikFallback";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/Rubik-Italic.ttf");
}

// Medium
@font-face {
  font-display: swap;
  font-family: "RubikFallback";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Rubik-Medium.ttf");
}

// Medium
@font-face {
  font-display: swap;
  font-family: "RubikFallback";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/Rubik-MediumItalic.ttf");
}

// BOLD
@font-face {
  font-display: swap;
  font-family: "RubikFallback";
  font-weight: 700;
  src: url("../fonts/Rubik-Bold.ttf");
}

// BOLD ITALIC
@font-face {
  font-display: swap;
  font-family: "RubikFallback";
  font-weight: 700;
  src: url("../fonts/Rubik-BoldItalic.ttf");
}

// Bold
@font-face {
  font-display: swap;
  font-family: "RubikFallback";
  font-weight: 900;
  src: url("../fonts/Rubik-Black.ttf");
}

@font-face {
  font-display: swap;
  font-family: "RubikFallback";
  font-style: italic;
  font-weight: 900;
  src: url("../fonts/Rubik-BlackItalic.ttf");
}

// zcool font
@font-face {
  font-display: swap;
  font-family: "ZcoolFallback";
  font-weight: 400;
  src: url("../fonts/ZCOOLXiaoWei-Regular.ttf");
}

/* stylelint-enable */
