/*=========================================
** CHARTS **
==========================================*/
@import "./charts/barchart";
@import "./charts/pie-chart";
@import "./charts/doughnut-kpi";
@import "./charts/chart-switcher";
@import "./charts/score-types-list";

// Custom styles fo mock legends
// Need to match chartColorsSettings.js
// Turn charts responsive
.chart {
  // height: 95%;
  // position: relative;
  // width: 95%;
}
.chart-legends {
  color: #fff;
  display: flex;
  font-size: 12px;

  .legend {
    align-items: flex-start;
    display: flex;
    .box {
      background-color: $hl-purple;
      border: rem-calc(1) solid $hl-green;
      display: block;
      height: 14px;
      width: 40px;
    }
    .label {
      line-height: 1;
      padding-left: 10px;

      &.crossed {
        text-decoration: line-through;
      }
    }
    + .legend {
      padding-left: 10px;
    }
  }
}
