/*=========================================
** DOUGHNUT KPI INFO CHART **
==========================================*/
.doughnut-kpi {
  display: grid;
  grid-template-columns: 2fr 1fr;
  min-width: rem-calc(200);

  .big-value {
    color: #ffffff;
    display: inline-block;
    font-size: calc(1rem * 5);
    font-weight: 300;
    width: 100%;
  }

  .floor-label {
    grid-column: 1 / span 4;
    text-transform: uppercase;
    color: $grey;
  }

  .doughnut-chart {
    margin: auto;
    width: 75px;
    height: 75px;
  }
}

