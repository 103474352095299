/*=========================================
** FORMS **
==========================================*/
// INPUTS
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
textarea {
  background-color: $input-background-color;
  border: none;
  border-radius: rem-calc(5);
  color: $input-text-color;
  display: flex;
  font-size: $input-font-size;
  font-weight: $font-weight-normal;
  line-height: $input-line-height;
  padding: $input-padding;
  width: 100%;
}

// TODO fix sizings of fonts
input[type="submit"] {
  background-color: $input-submit-bg-color;
  border: none;
  color: $input-submit-text-color;
  cursor: pointer;
  font-size: $input-submit-font-size;
  font-weight: $font-weight-normal;
  letter-spacing: $input-submit-letter-spacing;
  padding: $default-padding calc(#{$default-padding} * 2);
  text-transform: $input-submit-text-transform;

  &:hover {
    background-color: $input-submit-hover-bg-color;
    color: $input-submit-hover-text-color;
  }

  &:active {
    background-color: $input-submit-active-bg-color;
    color: $input-submit-active-text-color;
  }
}

// Overides for number spinners
input[type="number"] {
  -moz-appearance: textfield;
}

input::placeholder {
  color: #dcdcdc;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
