/*=========================================
** PLANS **
==========================================*/

.plans {
  &-wrapper {
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: 1fr 1fr;

    .sides-headings {
      align-items: center;
      background-color: $grey;
      display: flex;
      padding: calc(#{$default-padding} / 2);

      .sides-header {
        font-weight: $font-weight-bold;
        margin-right: $default-margin;
      }

      span + span {
        padding-left: $default-padding;
      }
    }
  }
}
