/*=========================================
** LINKS **
==========================================*/

a {
  color: $link-color;
  text-decoration: none;
  &:hover {
    color: $link-hover-color;
  }
}