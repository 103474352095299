/*=========================================
** EPISODE DETAILS**
==========================================*/

.episode-details {
  display: grid;
  grid-gap: $grid-gutter;
  grid-template-columns: 1fr 2fr;

  .episodes-chart {
    height: calc(100vh - 3.75rem);
    overflow-y: scroll;
    padding-left: $default-padding;
  }

  .no-scores {
    padding-right: $default-padding;
    padding-top: $default-padding;
  }

  .scores-fail {
    color: $white;
  }
}
