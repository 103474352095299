.chart-switcher-container,
.view-switcher {
  align-items: center;
  display: flex;
  margin-bottom: calc(#{$default-margin} / 2);

  .view-switcher-title {
    padding: 0;
  }

  ul {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0;
    padding-left: $default-padding;
  }
  li {
    background: $white;
    border-radius: rem-calc(5);
    margin: rem-calc(5);
    padding: rem-calc(5);
  }
}

.view-switcher {
  li {
    display: none;
    margin: 0;
    &.visible {
      display: inline-block;
    }
  }
}
