/*=========================================
** CHECKBOXES **
==========================================*/
.checkbox-holder {
  .item {
    margin-bottom: $default-margin;
    input {
      appearance: none;
    }
    label {
      align-items: center;
      display: flex;
      font-weight: $font-weight-thin;
      margin-bottom: 0;
      padding: 0 0 0 calc(#{$checkbox-regular-padding} * 1.5);
      position: relative;
      &::after {
        border: rem-calc(1) solid $hl-pink;
        content: "\00a0";
        display: inline-block;
        height: $checkbox-regular-height;
        left: 0;
        position: absolute;
        transition: $short-timing $ease-anim;
        width: $checkbox-regular-width;
      }
      &:hover {
        cursor: pointer;
        &::after {
          border: rem-calc(1) solid $grey;
        }
      }
    }

    input:checked + label::after {
      background: $hl-pink;
      border: rem-calc(1) solid $grey;
      box-shadow: 0 0 0 2px $white inset;
      content: "\00a0";
      height: $checkbox-regular-height;
      transition: $short-timing $ease-anim;
      width: $checkbox-regular-width;
    }
  }
}
