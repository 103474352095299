/*=========================================
** OPERATIONS **
==========================================*/
.operations {
  .table-container {
    .add-episodes {
      .selection-switcher {
        .selected-holder,
        .deselected-holder {
          .svg-icon-holder {
            background: none;
            height: 15px;
            width: 100%;
            svg {
              height: 20px;
              width: 20px;
            }
          }
        }

        .selected-holder {
          &:hover {
            cursor: pointer;

            svg {
              stroke: $hl-purple;
            }
          }
        }

        .deselected-holder {
          .svg-icon-holder {
            svg {
              stroke: $hl-purple;
            }
          }
        }
      }
    }
  }
  .ops-cards-container {
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: 1fr 1fr;

    .op-card {
      align-items: flex-start;
      border: rem-calc(1) solid $black;
      border-radius: rem-calc(3);
      box-shadow: 0 0 10px 3px $grey;
      display: flex;
      flex-direction: column;
      padding: $default-padding;
    }
  }
}
