/*=========================================
** LABELS **
==========================================*/
label {
  // @include text-accent;
  color: $label-text-color;
  display: flex;
  font-weight: $font-weight-normal;
  margin-bottom: $label-bottom-margin;
  width: 100%;

  &.label-active {
    color: $label-active-text-color;
  }
}
