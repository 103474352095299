/*=========================================
** MODAL **
==========================================*/
.modal {
  align-items: center;
  overflow-y: scroll;

  background: radial-gradient(
    circle closest-corner at 25% 15%,
    $hl-black 50px,
    $hl-black
  );

  display: flex;
  height: 100vh;
  justify-content: center;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  transition: all $short-timing $std-anim;
  visibility: hidden;
  width: 100%;

  &.open {
    height: 100%;
    opacity: 1;
    position: fixed;
    visibility: visible;
    z-index: 2;
  }

  &-inner {
    border-radius: $std-border-radius;
    min-width: 50%;
    height: 100%;
    padding: $default-padding;
    position: relative;
  }

  .close-btn {
    cursor: pointer;
    position: fixed;
    top: 3%;
    left: 3%;
  }
}
