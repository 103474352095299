/*=========================================
** BODY DEFAULTS **
==========================================*/
body {
  background-color: $body-bg-color;
  color: $body-text-color;
  font-family: $site-font;
  font-size: $default-font-size;
  font-weight: $font-weight-normal;
  line-height: $line-height;
}

/*=========================================
** TYPOGRAPHY **
==========================================*/

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: $white;
  font-weight: $font-weight-normal;
}

h1 {
  font-size: $h1-size;
}

h2 {
  font-size: $h2-size;
}

h3 {
  font-size: $h3-size;
}

h4 {
  font-size: $h4-size;
}

h5 {
  font-size: $h5-size;
}

h6 {
  font-size: $h6-size;
}

@media #{$medium-up} {
  h1 {
    font-size: $h1-tablet-up-size;
  }

  h2 {
    font-size: $h2-tablet-up-size;
  }

  h3 {
    font-size: $h3-tablet-up-size;
  }

  h4 {
    font-size: $h4-tablet-up-size;
  }

  h5 {
    font-size: $h5-tablet-up-size;
  }

  h6 {
    font-size: $h6-tablet-up-size;
  }
}

@media #{$xxlarge-up} {
  h1 {
    font-size: $h1-large-up-size;
  }

  h2 {
    font-size: $h2-large-up-size;
  }

  h3 {
    font-size: $h3-large-up-size;
  }

  h4 {
    font-size: $h4-large-up-size;
  }

  h5 {
    font-size: $h5-large-up-size;
  }

  h6 {
    font-size: $h6-large-up-size;
  }
}

/*=========================================
** IMAGES **
==========================================*/
img {
  display: inline-block;
  height: auto;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
  vertical-align: middle;
}
