/*=========================================
** LOADING **
==========================================*/

.loading {
  align-items: center;
  background: radial-gradient(
    circle closest-corner at 25% 15%,
    $hl-black 50px,
    $hl-black
  );
  display: flex;
  height: calc(100vh - #{$header-height});

  justify-content: center;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: all $short-timing $std-anim;
  visibility: hidden;
  width: 100%;
  z-index: 0;

  &.open {
    height: calc(100vh - #{$header-height});
    opacity: 1;
    visibility: visible;
  }

  &-inner {
    display: flex;
    flex-direction: column;
  }
}

// Loading bars animation
.loading {
  .loading-anim-wrapper {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(3, 18px);
    height: 100px;
    justify-content: center;
    padding: $default-padding;
    position: relative;

    .loading-bar {
      animation: loading-anim 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite;
      border: solid 1px $white;
      border-radius: 2px;
      position: relative;
      width: 18px;

      &:nth-child(1) {
        animation-delay: -0.48s;
      }

      &:nth-child(2) {
        animation-delay: -0.36s;
      }

      &:nth-child(3) {
        animation-delay: -0.24s;
      }
    }
  }
}

// loading text animations
.loading {
  .loading-text-holder {
    display: flex;
    justify-content: center;

    .loading-letter {
      animation: loading-text-anim 1.5s cubic-bezier(0, 0.5, 0.5, 1) infinite;
      font-weight: $font-weight-medium;
      padding: 0 3px;
      text-transform: uppercase;

      &:nth-child(1) {
        animation-delay: -0.48s;
      }

      &:nth-child(2) {
        animation-delay: -0.36s;
      }

      &:nth-child(3) {
        animation-delay: -0.24s;
      }
    }
  }
}

@keyframes loading-anim {
  0% {
    background: $hl-purple;
    height: 81px;
    top: 0px;
  }
  50% {
    background-color: #3a2c54;
  }
  100% {
    background: $hl-purple;
    height: 56px;
    top: 19px;
  }
}

@keyframes loading-text-anim {
  0% {
    color: $hl-purple;
  }
  50% {
    color: #3a2c54;
  }
  100% {
    color: $hl-purple;
  }
}
