/*=========================================
** PROFILE **
==========================================*/
.profile {
  &-inner {
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: 1fr 2fr 2fr;
    height: calc(100vh - #{$header-height});
    overflow-y: scroll;
    padding: $default-padding;
  }

  .profile-details-holder {
    .profile-box-title {
      color: $white;
      text-transform: uppercase;
    }

    .profile-details-list {
      &-item {
        display: flex;
        font-size: 1rem;
        justify-content: space-between;

        &-title {
          color: $hl-purple;
          text-transform: uppercase;
        }

        &-result {
          color: $white;
        }
      }
    }
  }
}

@import "./profile/profile-avatar";
@import "./profile/profile-demographics";
@import "./profile/profile-extra-details";
@import "./profile/profile-colleagues";
@import "./profile/password-check";
