/*=========================================
** TOGGLES **
==========================================*/

.toggle-holder {
  .toggle-switches-holder {
    background: $white;
    border: solid 1px $grey;
    border-radius: $toggle-border-radius;
    overflow: hidden;
    position: relative;
    transition: $short-timing $ease-anim;
    width: $toggle-width;

    &-inner {
      display: flex;
      transform: translateX(-50%);
      transition: transform $short-timing $ease-anim;
      width: calc(#{$toggle-width} * 2);

      .toggle-input {
        display: none;
      }

      .toggle-label {
        align-items: center;
        border-radius: $toggle-border-radius;
        cursor: pointer;
        margin-bottom: 0;
        padding: calc(#{$toggle-padding} / 2) 0;
        position: relative;
        width: $toggle-width;

        &::before {
          // resets the label pre border
          border-bottom: $toggle-border-radius;
        }

        &.untoggled {
          background-color: $toggle-bg-color;
          color: $toggle-text-color;
          padding-left: calc(#{$toggle-padding} + 20px); // custom

          &::before {
            background: $toggle-bg-toggled-color;
            border-radius: 100%;
            content: "";
            height: $toggle-btn-width;
            left: 5px; //custom
            position: absolute;
            width: $toggle-btn-width;
          }
        }

        &.toggled {
          background-color: $toggle-bg-toggled-color;
          color: $toggle-text-toggled-color;
          padding-left: 10px;

          &::before {
            display: none;
          }

          &::after {
            background: $toggle-bg-color;
            border-radius: 100%;
            color: $toggle-bg-toggled-color;
            content: "";
            height: $toggle-btn-width;
            position: absolute;
            right: 5px; //custom
            width: $toggle-btn-width;
          }
        }
      }

      &.toggle-on {
        transform: translateX(0);
      }
    }

    &.reversed {
      .toggle-switches-holder-inner {
        flex-direction: row-reverse;
      }
    }
  }
}
