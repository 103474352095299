/*=========================================
** GROUPS MAKER **
==========================================*/

.groups-maker {
  .groups-maker-header-title {
    padding-bottom: $default-padding;
    text-transform: uppercase;
  }

  .groups-maker-details {
    border-top: $primary-bd;
    padding: $default-padding 0;
    text-transform: uppercase;
  }

  .new-group-maker-name-form {
    border-top: $primary-bd;
    padding-top: $default-padding;

    .input-holder {
      display: flex;
      flex-direction: column;

      label {
        color: $white;
        justify-content: center;
        text-transform: uppercase;
      }
    }

    .submit-button {
      background-color: $hl-purple;
      border: $primary-bd;
      border-radius: $std-border-radius;
      color: $white;
      font-size: 1rem;
      padding: $default-padding;
      text-transform: uppercase;
      width: 100%;

      &:hover {
        background-color: $dark-grey;
      }
    }
  }
}
