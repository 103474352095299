/*=========================================
** GLOBAL WIDTH **
==========================================*/
$global-width: 1920px;

/*=========================================
** PADDINGS & MARGINS **
==========================================*/
$default-padding: rem-calc(15);
$default-margin: rem-calc(15);
$grid-gutter: rem-calc(15);
$page-padding: rem-calc(20); //used as side margins

/*=========================================
** FONTS **
==========================================*/
/* TODO: remove unused font weights and styles */
@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i");
@import url("https://fonts.googleapis.com/css?family=ZCOOL+XiaoWei");

$site-font: "Rubik", "RubikFallback", sans-serif;
$font-zcool: "ZCOOL XiaoWei", serif;
$font-weight-thin: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 900;
$default-font-size: 1rem;
$line-height: 1.45;

/*=========================================
** TYPOGRAPHY **
==========================================*/
// MOBILE HEADER SIZES
$h1-size: rem-calc(24);
$h2-size: rem-calc(20);
$h3-size: rem-calc(19);
$h4-size: rem-calc(18);
$h5-size: rem-calc(17);
$h6-size: rem-calc(16);

// TABLET UP HEADER SIZES
$h1-tablet-up-size: rem-calc(30);
$h2-tablet-up-size: rem-calc(25);
$h3-tablet-up-size: rem-calc(21);
$h4-tablet-up-size: rem-calc(18);
$h5-tablet-up-size: rem-calc(16);
$h6-tablet-up-size: rem-calc(14);

// LARGE UP HEADER SIZES
$h1-large-up-size: rem-calc(48);
$h2-large-up-size: rem-calc(40);
$h3-large-up-size: rem-calc(31);
$h4-large-up-size: rem-calc(25);
$h5-large-up-size: rem-calc(20);
$h6-large-up-size: rem-calc(16);

/*=========================================
** COLORS **
==========================================*/
// HL Color Scheme
$hl-white-faded: #a9a9a9;
$hl-black: #22222c;
$hl-grey-dark: #3a3e4e;
$hl-purple: #8142fd;
$hl-green: #49fcd4;//#49fcd4//33dbc6
$hl-yellow: #fde101;
$hl-pink: #fe0075;
$black: #000000;
$white: #ffffff;
$grey: #eeeeee;
$dark-grey: #525050;
$grid-box-background: $hl-black;
$g-dark: #292b31;
$g-light: #3a3a40;

/*** MESSAGE EVENT COLORS ***/
// Used with input validations
$success: $hl-green;
$warning: $hl-yellow;
$alert: $hl-pink;

/*=========================================
** BODY DEFAULT COLORS **
==========================================*/
$body-bg-color: $white;
$body-text-color: $black;
$global-letter-spacing: rem-calc(2);

/*=========================================
** INPUTS **
==========================================*/
$input-background-color: $grey;
$input-text-color: $black;
$input-padding: rem-calc(5);
$input-submit-bg-color: $white;
$input-submit-text-color: $black;
$input-submit-hover-bg-color: $white;
$input-submit-active-bg-color: $black;
$input-submit-hover-text-color: $black;
$input-submit-active-text-color: $white;
$input-font-size: rem-calc(18);
$input-line-height: 1.25;
$input-submit-font-size: rem-calc(24);
$input-submit-letter-spacing: rem-calc(2);
$input-submit-text-transform: lowercase;

/*=========================================
** CHECKBOX REGULAR  **
==========================================*/
$checkbox-regular-width: rem-calc(30);
$checkbox-regular-height: rem-calc(30);
$checkbox-regular-padding: rem-calc(30);

/*=========================================
** TOGGLES  **
==========================================*/
$toggle-bg-color: $white;
$toggle-text-color: $hl-pink;
$toggle-bg-toggled-color: $hl-pink;
$toggle-text-toggled-color: $white;
$toggle-padding: rem-calc(10);
$toggle-btn-width: rem-calc(20);
$toggle-border-radius: rem-calc(20);
$toggle-width: rem-calc(80);

/*=========================================
** RADIO **
==========================================*/
$radio-input-width: rem-calc(30);
$radio-input-height: rem-calc(30);
$radio-input-padding: rem-calc(30);

/*=========================================
** LABELS **
==========================================*/
$label-text-color: $black;
$label-active-text-color: transparentize($hl-black, 0.4);
$label-bottom-margin: rem-calc(10);

/*=========================================
** BUTTONS **
==========================================*/
$button-bg-color: $white;
$button-text-color: $black;
$button-active-bg-color: $black;
$button-active-text-color: $white;
$button-hover-bg-color: $white;
$button-hover-text-color: $grey;
$button-selected-color: $black;
$button-font-size: rem-calc(16);
$button-text-transform: lowercase;

/*=========================================
** TABLES **
==========================================*/
$table-header-bg-color: $grid-box-background;
$table-header-text-color: $white;
$table-header-borders: solid 1px $hl-white-faded;
$table-header-font-size: calc(#{$default-font-size} *  0.8);
$table-borders: none;
$table-body-bg-color: transparentize($hl-black, 0.4);
$table-body-text-color: $white;
$table-body-font-size: calc(#{$default-font-size} *  0.8);

/*=========================================
** MODAL **
==========================================*/
$modal-inner-bg-color: $grey;

/*=========================================
** LINKS **
==========================================*/
$link-color: $black;
$link-hover-color: $grey;

/*=========================================
** BORDERS **
==========================================*/
$primary-bd: 1px solid $dark-grey;
$std-border-radius: 5px;

/*=========================================
** MISC VARIABLES ** 
==========================================*/
// used to add small border before text elements
$text-accent-width: rem-calc(20);
$horizontal-separator-color: $hl-white-faded;

// default svg icon size
$default-icon-width: rem-calc(30);
$default-icon-height: rem-calc(30);

/*=========================================
** LOADING **
==========================================*/
$loading-bg-color: transparentize($grey, 0.3);
$loading-anim-color: $hl-yellow;

/*=========================================
** BREAKPOINT VARIABLES **
==========================================*/
$xsmall-only: "(max-width: 20rem)"; //320px max
$small-only: "(max-width: 39.9375rem)"; //638px max
$small-only-landscape: "(max-width: 39.9375rem) and (orientation: landscape)";
$medium-up: "(min-width: 40rem)"; //640px and up
$medium-only: "(min-width: 40rem) and (max-width: 63.938rem)"; //640px to 1023px
$medium-only-landscape: "(min-width: 40rem) and (max-width: 64rem) and (orientation: landscape)";
$large-up: "(min-width: 64rem)"; // 1024px and up
$large-only: "(min-width: 64rem) and (max-width: 74.9375rem)"; //1024px to 1198px
$xlarge-up: "(min-width: 75rem)"; //1200px and up
$xlarge-only: "(min-width: 75rem) and (max-width: 87.438rem)"; //1200px to 1440px
$xxlarge-up: "(min-width: 90rem)"; //1440px and up
$xxlarge-only: "(min-width: 90.0625rem) and (max-width: 120rem)"; //1440px to 1920px
$xxxlarge-up: "(min-width: 120.063rem)"; //1920px and up

/*=========================================
** ANIMATION VARIABLES **
==========================================*/
$short-timing: 0.4s;
$std-timing: 0.6s;
$medium-timing: 0.8s;
$long-timing: 1.3s;
$extra-long-timing: 1.5s;
$std-anim: ease-in-out;
$ease-anim: ease;

/*=========================================
** HEADER **
==========================================*/
$header-height: rem-calc(60);
