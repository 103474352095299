/*=========================================
** STD MIXINS**
==========================================*/

// Resets ::before pseudo element
@mixin list-reset {
  &:before {
    display: none;
  }
}

@mixin sentence-case {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

// rem-calculator
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}

// Adds border before text elements
@mixin text-accent {
  &::before {
    border-bottom: rem-calc(1) solid $black;
    content: "";
    display: inline-block;
    height: 20px;
    width: 20px;
  }
}

// SVG Circle
@mixin svg-circle {
  svg circle {
    fill: $hl-pink;
    stroke: $hl-pink;
    stroke-width: 0;
  }
}

// Radial Gradient Top/left (example)
// @include radial-gradient(#555A5F, #000);

@mixin radial-gradient($from, $to) {
  background: -moz-radial-gradient(
    top left,
    circle farthest-corner,
    $from 0%,
    $to 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0px,
    center center,
    100%,
    color-stop(0%, $from),
    color-stop(100%, $to)
  );
  background: -webkit-radial-gradient(
    top left,
    circle farthest-corner,
    $from 0%,
    $to 100%
  );
  background: -o-radial-gradient(
    top left,
    circle farthest-corner,
    $from 0%,
    $to 100%
  );
  background: -ms-radial-gradient(
    top left,
    circle farthest-corner,
    $from 0%,
    $to 100%
  );
  background: radial-gradient(
    top left,
    circle farthest-corner,
    $from 0%,
    $to 100%
  );
  background-color: $from;
}

// Linear Gradient (left to right)
// @include linear-gradient(#555A5F, #000);
@mixin linear-gradient($from, $to) {
  background: $to;
  background: -moz-linear-gradient(left, $from 0%, $to 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left right,
    color-stop(0%, $from),
    color-stop(100%, $to)
  );
  background: -webkit-linear-gradient(left, $from 0%, $to 100%);
  background: -o-linear-gradient(left, $from 0%, $to 100%);
  background: linear-gradient($from 0%, $to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to});
}

// Box Shadow Mixin
@mixin box-shadow($horiz, $vert, $blur, $spread, $color) {
  -webkit-box-shadow: $horiz $vert $blur $spread $color;
  -moz-box-shadow: $horiz $vert $blur $spread $color;
  box-shadow: $horiz $vert $blur $spread $color;
}
