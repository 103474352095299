/*=========================================
** WELCOME **
==========================================*/

.welcome {
  align-items: center;
  background: radial-gradient(
    circle closest-corner at 25% 55%,
    $g-light 150px,
    $g-dark
  );
  color: $white;
  display: grid;
  height: 100vh;

  &-inner {
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: 1fr 1fr 1fr;

    .login-section {
      align-items: center;
      border-right: solid 2px $white;
      display: flex;
      padding: $default-padding;

      // todo Overides for login should all be consistent
      .login {
        background: none;
        display: block;
        min-height: initial;

        .top-section {
          display: none;
        }

        .bottom-section {
          background: none;
        }
      }
    }

    .about-section {
      align-items: center;
      border-right: solid 2px $white;
      display: flex;
      flex-direction: column;
      padding: $default-padding;
      font-weight: 400;
      font-size: 1.1em;

      .login-title {
        background: transparentize($white, 0.9);
        border-radius: rem-calc(5);
        color: $white;
        font-family: $font-zcool;
        font-size: calc(#{$h1-size} * 2);
        font-weight: $font-weight-medium;
        letter-spacing: rem-calc(4); //custom
        line-height: 1;
        padding: $default-padding;
        text-align: center;
        width: rem-calc(450);
      }

      .link-text {
        align-items: center;
        color: $white;
        display: inline-flex;
        text-decoration: underline;
      }
    }

    .downloads-section {
      padding: $default-padding;
      text-transform: uppercase;

      .link-details-holder {
        padding: $default-padding 0;

        .download-link {
          align-items: center;
          color: $white;
          display: inline-flex;

          .svg-icon-holder {
            margin-right: $default-margin;
            width: 40px;

            svg {
              fill: $hl-purple;
              height: 35px;
              width: 35px;
            }
          }

          &:hover {
            color: $hl-purple;
          }
        }

        .instructions-link {
          align-items: center;
          display: flex;
          font-size: 0.8rem;
          padding-top: calc(#{$default-padding} / 2);

          .svg-icon-holder svg {
            height: 20px;
            margin-right: 5px;
          }

          &:hover {
            color: $hl-purple;
            cursor: pointer;
          }
        }
      }
    }
  }
}
