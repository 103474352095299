/*=========================================
** PROFILE DEMOGRAPHICS **
==========================================*/

.profile-demographics {
  .profile-box-title {
    color: $white;
    text-transform: uppercase;
  }

  .profile-details-list {
    &-item {
      display: flex;
      font-size: 1rem;
      justify-content: space-between;

      &-title {
        color: $hl-purple;
        text-transform: uppercase;
      }

      &-result {
        color: $white;
      }
    }
  }

  &.profile-colleagues {
    grid-column: 1 / span 4;
  }
}
