/*=========================================
** COMPLICATIONS **
==========================================*/

.complications {
  .side-result {
    padding-right: calc(#{$default-padding} / 2);
  }

  .table-container {
    .add-episodes {
      .selection-switcher {
        .selected-holder,
        .deselected-holder {
          .svg-icon-holder {
            background: none;
            height: 15px;
            width: 100%;
            svg {
              height: 20px;
              width: 20px;
            }
          }
        }

        .selected-holder {
          &:hover {
            cursor: pointer;

            svg {
              stroke: $hl-purple;
            }
          }
        }

        .deselected-holder {
          .svg-icon-holder {
            svg {
              stroke: $hl-purple;
            }
          }
        }
      }
    }
  }
}
