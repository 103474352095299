/*=========================================
** LOGIN **
==========================================*/
.login {
  align-items: center;
  display: grid;
  grid-template-rows: 1fr 1fr;
  min-height: 100vh;
  width: 100%;

  .top-section {
    align-items: center;
    background: linear-gradient(0deg, $hl-purple, $hl-grey-dark);
    display: flex;
    height: 100%;
    justify-content: center;

    .login-title {
      background: transparentize($white, 0.9);
      border-radius: rem-calc(5);
      color: $white;
      font-family: $font-zcool;
      font-size: calc(#{$h1-size} * 2);
      font-weight: $font-weight-medium;
      letter-spacing: rem-calc(4); //custom
      line-height: 1;
      padding: $default-padding;
      text-align: center;
      width: rem-calc(450);
    }
  }

  .bottom-section {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    @include radial-gradient($hl-grey-dark, transparentize($hl-black, 0.4));
    .login {
      &-form {
        @include radial-gradient(
          transparentize($white, 0.4),
          transparentize($hl-black, 0.8)
        );
        @include box-shadow(
          0px,
          0px,
          20px,
          2px,
          transparentize($hl-black, 0.4)
        );
        border: rem-calc(1) solid transparentize($hl-black, 0.4);
        border-radius: rem-calc(5);
        padding: calc(#{$default-padding} * 2);
      }
      &-forgot-password {
        margin-top: $default-margin;
        a {
          color: $hl-white-faded;
        }
      }
      &-fail-message {
        text-align: center;
      }
    }
  }

  // Custom Button
  .submit-button {
    background-color: $grey;
    border-radius: rem-calc(5);
    margin-top: $default-margin;
    width: 100%;
  }
}
