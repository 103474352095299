/*=========================================
** MESSAGES **
==========================================*/
.messages {
  animation: fadeInOut 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: 2;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 120;

  .messages-inner {
    color: $white;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr min-content;
    justify-content: center;
    padding: $default-padding;

    &.valid {
      background-color: $success;
    }

    &.invalid {
      background-color: $alert;
    }

    .message {
      text-align: center;
    }

    .svg-icon-holder {
      svg {
        fill: $white;
      }
    }
  }
}
