/*=========================================
** REUSABLES **
==========================================*/
/* 
  REUSABLE CLASSES APPLIED TO INDIVIDUAL ELEMENTS
  OR GROUPS OF STYLES THAT COVER MULTIPLE ELEMENTS 
  NEEDING SIMILAR BEHAVIOURS
 */

// Default icon div holder
.svg-icon-holder {
  // align-items: center;
  // display: flex;
  // flex-direction: row;
  // height: $default-icon-height;
  // justify-content: center;
  // width: $default-icon-width;
}

// Default component headers
.component-header {
  // @include text-accent;
  color: $hl-black;
  letter-spacing: rem-calc(2);
  padding: $default-padding;
  text-align: right;

  // padding-top: calc(#{$default-padding} * 4);
}

// Input Holder Pattern
.input-holder {
  margin-bottom: $default-margin;
  // Used to create password toggle - hidden by default
  .password-view-btn {
    display: none;
  }
  .item {
    display: flex;

    label {
      padding-left: $default-padding;

      &::before {
        display: none;
      }
    }
  }

  &.invalid {
    label::before {
      border-bottom-color: $alert;
    }
    .svg-container {
      svg circle,
      svg path,
      svg polygon,
      svg rect {
        fill: $alert;
      }
    }
  }
  &.valid {
    label::before {
      border-bottom-color: $success;
    }
    .svg-container {
      svg circle,
      svg path,
      svg polygon,
      svg rect {
        fill: $success;
      }
    }
  }
  @media #{$xlarge-up} {
    align-items: start;
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: 1fr 2fr;
  }
}
// Card Container
.card {
  &-container {
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.horizontal-separator {
  background-color: $horizontal-separator-color;
  height: rem-calc(5);
  width: 100%;
}

// TODO create perm fix
::-webkit-scrollbar {
  display: none;
}
