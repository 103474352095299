/*=========================================
** PROFILE AVATAR **
==========================================*/

.profile-avatar {
  color: $white;

  &-inner {
    border: $primary-bd;
    border-radius: $std-border-radius;
  }
  svg {
    height: 100%;
    width: 100%;
  }

  .profile-name-holder {
    padding: $default-padding;

    .profile-title {
      font-size: 1rem;
      padding-bottom: 0;
      text-align: center;
    }

    .profile-specialism {
      font-size: 1rem;
      text-align: center;

      &-title {
        color: $hl-purple;
        padding-right: 5px;
      }
    }
  }
}
