/*=========================================
** SVG CHART ICONS **
==========================================*/

.svg-chart-icon-holder {
  align-items: center;
  background: $white;
  display: flex;
  flex-direction: row;
  height: $default-icon-height;
  justify-content: center;
  width: $default-icon-width;

  .line-chart {
    .st0 {
      stroke: $hl-grey-dark;
    }
    .st1 {
      fill: $hl-green; 
    }
    .st2 {
      fill: $hl-pink;
    }
    .st3 {
      fill: $hl-yellow;
    }
    .st4 {
      fill: $white;
    }
  }

  .pie-chart {
    .st0 {
      stroke: $hl-grey-dark;
    }
    .st1 {
      fill: $hl-yellow;
    }
    .st2 {
      fill: $hl-pink;
    }
    .st3 {
      fill: $white;
    }
  }

  .scatter-chart {
    .cls-1 {
      fill: $hl-pink;
    }
    .cls-2 {
      fill: $hl-yellow;
    }
    .cls-3 {
      fill: $hl-green;
    }
    .cls-4 {
      fill: $hl-purple;
    }
    .cls-5 {
      fill: $hl-yellow;
    }
  }

  .radar-chart {
    .cls-1 {
      fill: $hl-yellow;
    }
    .cls-2 {
      fill: $hl-pink;
    }
    .cls-3 {
      fill: $hl-green;
    }
    .cls-4 {
      fill: $white;
      opacity: 0.4;
      stroke: $black;
    }
    polygon {
      stroke: $hl-grey-dark;
    }
    line {
      stroke: $hl-grey-dark;
    }
  }

  .horizontal-bar-chart,
  .vertical-bar-chart {
    line {
      stroke: $hl-grey-dark;
    }
    .cls-1 {
      fill: $hl-yellow;
    }
    .cls-2 {
      fill: $hl-green;
    }
    .cls-3 {
      fill: $hl-yellow;
    }
    .cls-4 {
      fill: $hl-purple;
    }
    .cls-5 {
      fill: $hl-pink;
    }
  }

  .polar-chart {
    path {
      stroke: $white;
    }
    .cls-1 {
      fill: $white;
      stroke: $hl-grey-dark;
    }
    .cls-2 {
      fill: $white;
      stroke: $hl-grey-dark;
    }
    .cls-3 {
      fill: $white;
      stroke: $hl-grey-dark;
    }
    .cls-5 {
      fill: $hl-green;
    }
    .cls-6 {
      fill: $hl-pink;
    }
    .cls-7 {
      fill: $hl-green;
    }
    .cls-8 {
      fill: $hl-yellow;
    }
    .cls-9 {
      fill: $hl-purple;
    }
    .cls-10 {
      fill: $hl-yellow;
    }
  }

  .bubble-chart {
    .cls-1 {
      fill: $hl-pink;
    }
    .cls-2 {
      fill: $hl-pink;
    }
    .cls-3 {
      fill: $hl-yellow;
    }
    .cls-4 {
      fill: $hl-green;
    }
    line {
      stroke: $hl-grey-dark;
    }
  }

  .area-chart {
    line {
      stroke: $hl-grey-dark;
    }
    .cls-0 {
      stroke: $hl-grey-dark;
    }
    .cls-1 {
      fill: $hl-green;
    }
    .cls-2 {
      fill: $hl-purple;
      opacity: 0.5;
    }
    .cls-3 {
      fill: $hl-green;
    }
    .cls-4 {
      fill: $hl-pink;
    }
    .cls-5 {
      fill: $hl-yellow;
    }
    .cls-6,
    .cls-7 {
      fill: $white;
    }
  }
}
