/*=========================================
** DROPDOWNS **
==========================================*/

.dropdown {
  &-element {
    position: relative;
  }
  &-toggle {
    align-items: center;
    border: rem-calc(1) solid $black;
    display: flex;
    justify-content: space-between;
    padding: calc(#{$default-padding} / 2);
    width: 100%;
    &:hover {
      color: $black;
      cursor: pointer;
    }
  }
  &-options-container {
    background: $white;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
    li {
      animation: slide-down;
      color: $grey;
      display: flex;
      justify-content: space-between;
      padding: calc(#{$default-padding} / 2);
      transition: $short-timing $ease-anim;
      svg {
        display: none;
      }
      &:hover {
        background: $hl-pink;
        color: $black;
        cursor: pointer;
        svg {
          display: block;
        }
      }
    }
    &.hidden {
      display: none;
    }
  }
}
