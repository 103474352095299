/*=========================================
** EPISODES **
==========================================*/
.episodes {
  overflow: scroll;
  &-header {
    display: flex;
    justify-content: space-between;
    h2 {
      width: 80%;
    }
  }

  th {
    background: $table-header-bg-color;
    border: 1px solid $hl-white-faded;
    color: $table-header-text-color;
    font-size: $table-header-font-size;
    font-weight: $font-weight-normal;
    padding: calc(#{$default-padding} / 2);
    text-transform: uppercase;

    .table-header-holder {
      align-items: center;
      display: flex;
      justify-content: center;

      .svg-icon-holder {
        align-items: center;
        display: flex;
        padding-left: 5px;

        svg {
          height: 18px;
          width: 18px;
        }
      }
    }
  }

  td {
    background: $white;
    border: 1px solid transparentize($hl-black, 0.8);
    color: $hl-black;
    font-size: $table-body-font-size;
    padding: calc(#{$default-padding} / 2);
    text-align: center;
  }

  label {
    margin: 0;
  }

  span {
    color: $white;
    margin-left: $default-margin;
  }

  .back-link {
    span {
      margin-left: 0;
    }
  }

  // Group row
  .group-table-row {
    display: grid;
    grid-template-columns: 2fr 1fr;
    .filters {
      &-container {
        display: flex;
        flex-direction: column;
        padding: 0 $default-padding;
      }
      &-categories {
        background: $hl-black;
        border-radius: rem-calc(10);
        display: flex;
      }
      &-columns,
      &-gender {
        padding: calc(#{$default-padding} / 2);
      }
    }
  }

  // Episodes Table
  .episodes-table {
    margin-top: $default-margin;
    p {
      color: $black;
    }
    .sorter-indicator {
      color: $hl-purple;
      margin-left: calc(#{$default-margin} / 4);
    }
    .filters-container {
      background: $hl-white-faded;
    }
  }

  // Selected Episode Rows Table
  .table-holder {
    p {
      color: $black;
    }
    .filters-container {
      background: $hl-white-faded;
    }
    .scores-icons {
      align-items: center;
      display: flex;
      justify-content: center;
      .svg-icon-holder {
        padding: 0 calc(#{$default-padding} / 2);
      }
    }
  }

  .table-body {
    td {
      vertical-align: top;
    }
  }
}
