/*=========================================
** GROUPS LIST MENU CONTAINER**
==========================================*/

.groups-list-menu {
  font-size: 0.9rem;
  height: calc(100vh - #{$header-height});
  overflow: hidden;
  padding: $default-padding;
}

// Search wrapper
.groups-list-menu {
  .search-filter-wrapper {
    align-items: center;
    background-color: transparentize($hl-black, 0.4);
    border-radius: rem-calc(5);
    display: flex;
    justify-content: space-between;
    margin-bottom: $default-padding;
    width: 100%;

    .filter-menu-holder {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 0;
      width: 100%;

      label {
        display: none;
      }
      .text-input {
        background-color: transparentize($hl-black, 0.4);
        border: 1px solid $hl-black;
        color: $white;
      }
    }
  }
}

// Groups list
.groups-list-menu {
  .groups-list {
    height: calc(
      100vh - calc(#{$header-height} + calc(#{$default-padding} * 4))
    );

    overflow-y: scroll;
    &-item {
      background-color: $hl-black;
      border-radius: rem-calc(5);
      margin-bottom: calc(#{$default-margin} / 2);
      padding: calc(#{$default-padding} / 3);

      .item-title {
        align-items: center;
        color: $white;
        display: flex;
        font-size: 0.9rem;
        justify-content: space-between;
        padding: calc(#{$default-padding} / 3);
        position: relative;
        text-transform: uppercase;

        .group-title {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

// count holder
.groups-list-menu {
  .counts-holder {
    padding: 0 calc(#{$default-padding} / 3);

    .counts-list-item {
      color: $hl-purple;
      display: flex;
      font-size: 0.8rem;
      justify-content: space-between;
      text-transform: uppercase;

      .count-title {
        &.episodes {
          color: $hl-green;
        }
        &.patients {
          color: $hl-yellow;
        }
        &.operations {
          color: $hl-pink;
        }
      }
    }
  }
}
