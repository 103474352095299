/*=========================================
** HEADER **
==========================================*/

.header {
  background-color: transparentize($hl-black, 0.4);
  border-bottom: $primary-bd;
  color: $white;

  &-inner {
    display: flex;
    height: 100%;
    justify-content: space-between;

    .logo {
      display: block;
      font-size: 2rem;
      color: $hl-pink;
      text-transform: uppercase;
      font-weight: 300;
      letter-spacing: 0.25rem;
      padding-left: 0.9375rem;
      margin-top: .2em;


      // display: block;
      // font-family: $font-zcool;
      // font-size: 2rem;
      // font-weight: $font-weight-medium;
      // letter-spacing: rem-calc(4); //custom
      // padding-left: $default-padding;
    }

    .profile-menu-holder {
      display: flex;
      height: 100%;

      .user-profile {
        align-items: center;
        display: flex;
        padding: 5px 0;
        padding-right: $default-padding;

        .user-title {
          line-height: 1;
          padding-right: $default-padding;
          text-transform: capitalize;
        }

        .profile-image-holder {
          display: flex;

          .profile-image {
            border: $primary-bd;
            border-radius: 2px;
            height: 45px;
            width: 45px;
          }

          svg {
            height: 35px;
            width: 35px;
          }
        }
      }
    }
  }
}
