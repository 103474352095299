/*=========================================
** DASHBOARD **
==========================================*/
.dashboard {
  background: radial-gradient(
    circle closest-corner at 25% 55%,
    $g-light 150px,
    $g-dark
  );
  &-inner {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: $header-height 1fr; //TODO use first val for 100vh fix
    min-height: calc(100vh - #{$header-height});
    width: 100%;

    .dashboard-content {
      min-height: calc(100vh - #{$header-height});
      min-width: 100%;
    }
  }

  &.overlay-open {
    height: 100vh;
    overflow: hidden;
  }
}
