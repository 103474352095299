.score-types-list {
  display: grid;
  justify-items: right;
  padding-right: $default-padding;
  padding-top: $default-padding;
  &.chart-page {
    justify-content: space-between;
  }
  .score-types-items {
    align-items: center;
    display: flex;
    li {
      background-color: $hl-grey-dark;
      border: 1px solid $black;
      color: $hl-green;
      padding: calc(#{$default-padding} / 2);
      text-align: center;
      transition: all 0.5s ease-in-out;
      width: 50%;

      + li {
        margin-top: $default-margin;
      }

      &:hover {
        background-color: $hl-grey-dark;
        cursor: pointer;
      }
    }
    label {
      color: $white;
      padding-left: $default-padding;
      width: initial;
    }

    input {
      margin-right: $default-margin;
    }
  }
}

.episodes-chart {
  .score-types-list {
    display: flex;
    margin: auto;
  }
}
