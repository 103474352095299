/*=========================================
** TIMELINE**
==========================================*/

.timeline {
  // display: grid;
  // grid-template-rows: 100px repeat(auto-fill, minmax(250px, 1fr));
  height: calc(100vh - #{$header-height});
  overflow-y: scroll;
  padding-left: $default-padding;

  .empty-line-holder {
    width: 100px;

    .timeline-spacer-holder {
      display: flex;
      height: 100%;
      justify-content: center;
      position: relative;

      .spacer {
        background: $hl-pink;
        display: block;
        min-height: 100px;
        width: 3px;
      }

      .terminator {
        background: $hl-pink;
        display: block;
        height: rem-calc(30);
        position: absolute;
        top: calc(50% - 5px);
        width: rem-calc(30);
        border-radius: 50%;
      }
    }
  }
}

@import "./timeline/timeline-item";
