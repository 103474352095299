/*=========================================
** RESET **
==========================================*/
html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

input:focus,
textarea:focus,
a,
button {
  outline: none;
}

/*=========================================
** LISTS **
==========================================*/
ol,
ul {
  list-style-position: inside;
}

/*----------------------------------------
 ORDERED LIST STYLES
------------------------------------------*/
ol {
  counter-reset: item;
  margin: 0 0 1.5rem;
  padding: 0;
}

ol > li {
  counter-increment: item;
  margin: 0;
}

/*----------------------------------------
 UNORDERED LIST STYLES
------------------------------------------*/
ul {
  margin: 0 0 1.5rem;
  padding: 0;
}

ul > li {
  list-style-type: none;
  margin: 0;
}
