/*=========================================
** TIMELINE ITEM **
==========================================*/

.timeline-item {
  display: grid;
  grid-gap: $grid-gutter;
  grid-template-columns: min-content 1fr;
}

// Lollipops
.timeline-item {
  .timeline-date-holder {
    .timeline-date-lollipop {
      align-items: center;
      border-radius: 50%;
      display: flex;
      height: 100px;
      justify-content: center;
      padding: $default-padding;
      width: 100px;

      .timeline-date-lollipop-inner {
        color: $white;
        display: grid;
        grid-template-rows: 1fr 1fr;
        justify-items: center;

        .date-text {
          text-transform: uppercase;

          &.month {
            padding-left: calc(#{$default-padding} / 3);
          }
        }
      }
    }
  }
}

// Timeline stick
.timeline-item {
  .timeline-spacer-holder {
    display: flex;
    height: 100%;
    justify-content: center;

    .spacer {
      background: $hl-pink;
      display: block;
      min-height: 100px;
      width: 3px;
    }
  }
}

.timeline-item {
  .timeline-item-content {
    padding: $default-padding 0;
  }
}

// Overides based upon node type
.timeline-item {
  &-visit {
    .timeline-date-holder .timeline-date-lollipop {
      background-color: $hl-green;
      border: solid 2px $white;
    }
  }

  &-plan {
    .timeline-date-holder .timeline-date-lollipop {
      background-color: $hl-purple;
      border: solid 2px $white;
    }
  }
  &-operation {
    .timeline-date-holder .timeline-date-lollipop {
      background-color: $hl-yellow;
      border: solid 2px $white;
    }
  }
  &-diagnosis {
    .timeline-date-holder .timeline-date-lollipop {
      background-color: $hl-grey-dark;
      border: solid 2px $white;
    }
  }
}

.timeline-item {
  .timeline-item-title {
    text-transform: uppercase;

    .pre-title {
      color: $hl-white-faded;
    }
  }

  .content-date {
    border-top: solid 2px $hl-white-faded;
    color: $hl-white-faded;
    font-size: calc(#{$default-font-size} / 1.1);
    margin-top: $default-margin;
    padding: 0;
    padding-top: calc(#{$default-padding} / 2);
    text-transform: uppercase;
  }

  .lede {
    color: $white;
    font-size: calc(#{$default-font-size} * 1.5);
    font-weight: 300;
  }
}
