/*=========================================
** GROUPS COMPARE LIST CONTAINER**
==========================================*/
.groups-compare-list {
  .groups-compare-list-title {
    margin-bottom: $default-margin;
    text-transform: uppercase;
  }

  .groups-compare-name-list {
    .groups-compare-names-list-item {
      border-bottom: $primary-bd;
      border-top: $primary-bd;
      display: flex;
      justify-content: space-between;
      padding: calc(#{$default-padding} / 2) 0;

      &-title {
        text-transform: uppercase;
      }

      .remove-compare-group-holder {
        color: $hl-pink;
      }
    }
  }
  .compare-groups-list-button {
    background-color: $hl-purple;
    border: $primary-bd;
    border-radius: $std-border-radius;
    color: $white;
    padding: $default-padding;
    text-transform: uppercase;
    width: 100%;

    &:hover {
      background-color: $dark-grey;
    }
  }
}
