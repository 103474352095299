/*=========================================
** SCORESEXPLORER **
==========================================*/

.scores-explorer {
  min-height: calc(100vh - #{rem-calc(92)});

  .scores-content {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;

    .scores-content-inner {
      display: grid;
      grid-template-columns: 2fr 1fr;

      .scores-chart-holder {
        align-self: center;
      }
    }
  }

  .scores-list-holder {
    text-align: right;
  }

  .scores-header {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .view-switcher {
      justify-content: flex-end;
    }
  }

  .score-types-list {
    display: grid;
    justify-items: right;
    padding-top: 1.5rem;

    li {
      background-color: $hl-grey-dark;
      border: 1px solid $black;
      color: $hl-green;
      padding: calc(#{$default-padding} / 2);
      text-align: center;
      transition: all 0.5s ease-in-out;
      width: 50%;

      + li {
        margin-top: $default-margin;
      }

      &:hover {
        background-color: $hl-grey-dark;
        cursor: pointer;
      }
    }
  }
}
