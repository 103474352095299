/*=========================================
** DATE PICKER **
==========================================*/
.date-picker {
  width: 80%;
  margin: auto;
  color: $white;
  padding: 0;
  @media #{$large-up} {
    width: 50%;
  }

  .selected-dates-holder {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .calendar-button-holder {
    display: flex;
    align-items: center;
    padding: $default-padding;
  }

  .selected-dates-result-holder {
    justify-content: space-around;
    display: flex;
    align-items: center;

    .start-date, 
    .end-date {
      padding: $default-padding;
    }
  }

  // date selection wrapper styles
  .date-selector-wrapper {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;

    .date-title-holder {
      align-items: center;
      display: flex;
    }
  }
}

// overlay calendar
.date-picker {
  .overlay-calendar {
    @include box-shadow(2px, 2px, 5px, 2px, $hl-black);

    // General cell styles
    .cell {
      border: solid 1px $white;
      text-align: center;
    }

    // header
    .date-picker-header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      .svg-icon-holder {
        margin: auto;
      }
    }

    .days-holder {
      border-bottom: rem-calc(1) solid $hl-black;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }

    .date-grid {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }
  }
}

// Overlay calendar toggle visibility
.date-picker {
  .overlay-calendar {
    background: $white;
    color: $hl-black;
    display: none;

    &.active {
      display: block;
    }
  }
}

// Special styles for selected and in future dates
.date-picker {
  .date-cells {
    cursor: pointer;
    &.disabled {
      color: $hl-white-faded;
      cursor: not-allowed;
    }

    &.selected {
      &.start {
        color: $white;
        background-color:$hl-green;
      }

      &.end {
        color: $white;
        background-color: $hl-green;
      }
    }

    .inner-cell.range {
      color: $hl-black;
      background-color: transparentize($hl-black, 0.8);
    }
  }

  // Bottom Actions Buttons
  .actions {
    padding: calc(#{$default-padding} / 3);
    width: 30%;
    display: grid;
    grid-template-columns: 1fr 1fr;

    span {
      padding: calc(#{$default-padding} / 3);
      display: inline-block;
      text-align: center;
    }
    .apply-dates {
      background-color: $hl-green;
      color: $white; 
    }
  }
}




