.radio-holder {
  .item {
    margin-bottom: $default-margin;
    input {
      appearance: none;
    }
    label {
      align-items: center;
      display: flex;
      line-height: $radio-input-height;
      margin-bottom: 0;
      padding: 0 0 0 calc(#{$radio-input-padding} * 1.5);
      position: relative;
      &::after {
        background: $white;
        border: 1px solid $hl-pink;
        border-radius: 99px;
        content: "\00a0";
        height: $radio-input-height;
        left: 0;
        position: absolute;
        transition: $short-timing $ease-anim;
        width: $radio-input-width;
      }
      &:hover {
        cursor: pointer;
        &::after {
          border: 1px solid $grey;
        }
      }
    }

    input:checked + label::after {
      background:$hl-pink;
      border: 1px solid $grey;
      box-shadow: 0 0 0 2px $white inset;
      content: "\00a0";
      height: $checkbox-regular-height;
      transition: $short-timing $ease-anim;
      width: $checkbox-regular-width;
    }
  }
}
