/*=========================================
** VISITS **
==========================================*/

.visits {
  display: grid;
  grid-template-columns: 1fr;

  .visits-header {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    h2 {
      padding: 0;
    }
  }
  .inner {
    overflow-y: scroll;
  }
}
