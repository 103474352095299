.grid-box {
  @include box-shadow(2px, 2px, 5px, 2px, $hl-black);
  background-color: transparentize($hl-black, 0.4);
  padding: $default-padding;

  h4 {
    color: $white;
    font-size: calc(#{$default-font-size} / 1.5);
    padding: 0 0 $default-padding 0;
    text-transform: uppercase;
  }

  h2 {
    color: $white;
    font-weight: 300;
    padding: 0 0 $default-padding 0;
    text-transform: uppercase;
  }

  p {
    color: $hl-white-faded;
    padding: $default-padding;
  }

  .separator-line {
    background: $white;
    display: block;
    height: rem-calc(1);
    width: 100%;
  }

  .separator-line-muted {
    background: $dark-grey;
    display: block;
    height: rem-calc(1);
    width: 100%;
  }

  // General Box
  &.general {
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    .counter {
      margin: auto 0;
      width: rem-calc(150);
      span {
        color: $white;
        font-size: calc(#{$default-font-size} * 5);
        font-weight: $font-weight-thin;
        text-transform: uppercase;
      }
    }
    .download {
      span {
        color: $hl-green;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  // Scores Box
  &.score-types {
    .pie-chart {
      background: $white;
      & > div {
        margin: auto;
      }
    }
  }
}
