/*=========================================
** PATIENTS **
==========================================*/

.patients {
  .charts-holder {
    display: grid;
    grid-template-rows: 1fr 1fr;
    height: 100%;

    .top-row {
      align-items: center;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .bottom-row {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
    }
  }

  .patient-legends-holder {
    display: flex;
    justify-content: center;
    padding: $default-padding;

    .legend {
      .box {
        &.male {
          background-color: $hl-green;
          border: rem-calc(1) solid transparentize($hl-green, 0.4);
        }
        &.female {
          background-color: $hl-pink;
          border: rem-calc(1) solid transparentize($hl-pink, 0.4);
        }
      }
    }
  }
}
