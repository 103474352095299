.diagnoses {
  display: grid;
  grid-template-columns: 1fr;
  height: 92vh;

  .diagnosis-header {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    h2 {
      padding: 0;
    }

    .view-switcher {
      justify-content: flex-end;
    }
  }

  .inner {
    overflow-y: scroll;
  }

  .table-container {
    .add-episodes {
      .selection-switcher {
        .selected-holder,
        .deselected-holder {
          .svg-icon-holder {
            background: none;
            height: 15px;
            width: 100%;
            svg {
              height: 20px;
              width: 20px;
            }
          }
        }

        .selected-holder {
          &:hover {
            cursor: pointer;

            svg {
              stroke: $hl-purple;
            }
          }
        }

        .deselected-holder {
          .svg-icon-holder {
            svg {
              stroke: $hl-purple;
            }
          }
        }
      }
    }
  }

  .chart-fixed-wrapper {
    position: relative;

    &.small {
      width: 60%;
    }
  }
}
