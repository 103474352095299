/*=========================================
** GROUPS SINGLE VIEW CONTAINER**
==========================================*/
.groups-single-view {
  display: grid;
  grid-gap: $grid-gutter;
  grid-template-rows: min-content 1fr;
  height: calc(100vh - #{$header-height});
  overflow-y: scroll;
  padding: $default-padding;

  &-header {
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: 1fr 2fr;
  }

  &-content {
    display: grid;
    grid-gap: $grid-gutter;
    grid-template-columns: 1fr;

    .checkbox-labels {
      color: $table-header-text-color;
      padding-left: $default-padding;
    }

    .splits {
      display: grid;
      grid-gap: $grid-gutter;
      grid-template-columns: 1fr 1fr;
    }
  }
}
