/*=========================================
** MAIN MENU **
==========================================*/

.main-menu {
  display: flex;
  overflow: hidden;

  .menu-links-list {
    display: flex;
    margin-bottom: 0;
    max-width: 0;
    transition: all $long-timing $std-anim;

    &-item {
      background-color: $hl-black;
      border-left: $primary-bd;
      font-size: 0.9rem;
      min-width: 100px;
      text-transform: uppercase;
      transform: translateX(100%);
      transition: all $medium-timing $std-anim;

      &:nth-of-type(1) {
        transform: translateX(300%);
        transition-delay: 0.7s;
      }
      &:nth-of-type(2) {
        transform: translateX(200%);
        transition-delay: 0.4s;
      }
      &:nth-of-type(3) {
        transform: translateX(100%);
        transition-delay: 0.1s;
      }

      a {
        align-items: center;
        color: $hl-purple;
        display: flex;
        height: 100%;
        justify-content: center;
        padding: 0 $default-padding;
        transition: all $std-timing $std-anim;

        &:hover {
          background-color: $hl-grey-dark;
          color: $white;
        }

        &.active {
          background-color: $hl-grey-dark;
          color: $white;
        }
      }

      &:last-child {
        border-right: $primary-bd;
      }
    }
  }

  .menu-icon-holder {
    align-items: center;
    background-color: $hl-black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: calc(#{$default-padding / 2});
    transition: all $std-timing $std-anim;
    width: $header-height;
    z-index: 1;

    .menu-line {
      background-color: $white;
      border-radius: 2px;
      height: 3px;
      transform: rotateZ(0);
      transition: all $std-timing $std-anim;
      width: 30px;

      + .menu-line {
        margin-top: 7px;
      }
    }

    &:hover {
      background-color: $hl-grey-dark;
      cursor: pointer;

      .menu-line {
        background-color: $hl-purple;
      }
    }
  }

  &.active {
    .menu-links-list {
      max-width: 1000px;

      &-item {
        transform: translateX(0);

        &:nth-of-type(1) {
          transition-delay: 0.1s;
        }
        &:nth-of-type(2) {
          transition-delay: 0.4s;
        }
        &:nth-of-type(3) {
          transition-delay: 0.7s;
        }
      }
    }
    .menu-icon-holder {
      background-color: $hl-grey-dark;

      .menu-line {
        background-color: $hl-purple;
        &:nth-of-type(1) {
          opacity: 0;
        }
        &:nth-of-type(2) {
          height: 4px;
          transform: rotateZ(45deg);
          width: 35px;
        }
        &:nth-of-type(3) {
          height: 4px;
          transform: rotateZ(-45deg) translateX(8px) translateY(-8px);
          width: 35px;
        }
      }
    }
  }
}

// Hides Electron dummy div
.electron-nav {
  display: none;
}